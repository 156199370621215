.HelpButton {
  &.MuiButtonBase-root {
    margin-bottom: 12px !important;
    background-color: #e5e8f3 !important;
    color: #414141 !important;
    box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.4) !important;
    border-radius: 30px !important;
    text-transform: inherit !important;
  }

    .MuiButton-text {
      padding: 6px 12px;
    }

  .MuiButton-label {
    justify-content: normal;
    font-size: 14px;
    color: #414141;

    .MuiSvgIcon-root {
      margin-right: 0.5rem;
    }
  }
}
