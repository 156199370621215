.GedBreadCrumb {
  height: 20px;
  display: flex;
  padding-right: 16px;
  padding-top: 16px;
  padding-left: 16px;

  @media (max-width: 767px) {
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .separator {
    color: #134594;
    font-weight: bold;
    display: inline-block;
  }

  .folderContainer {
    display: flex;
  }

  .GedBreadcrumbOtherParents {
    padding: 0 16px;
  }
}
