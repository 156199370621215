.Login {
  flex-grow: 1;
  max-width: 95%;

  h2 {
    color: #134594;
    font-size: 36px;
    font-weight: inherit;
    margin-bottom: 36px;
  }

  h3 {
    color: #134594;
    font-size: 24px;
    font-weight: inherit;
    margin-bottom: 24px;
  }

  input {
    background-color: white;
  }

  .MuiFormControl-root {
    margin: 0 0 12px 0;
  }

  .MuiCircularProgress-root {
    color: white;
  }

  button {
    margin-top: 36px !important;

    &:hover {
      background-color: darken(#134594, 1%);
    }
  }

  .Mui-disabled {
    background-color: #999 !important;
  }

  a {
    color: #42b7c8;
    font-size: 15px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  form {
    text-align: left;
  }

  label {
    color: #b7c2ff;
  }

  .lds-dual-ring {
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin: 0px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
