.PublicDocsToolbar {
  height: 32px;
  display: flex;
  gap: 8px;
  align-items: center;

  @media (max-width: 767px) {
    height: 60px;
  }

  padding: 8px;

  .tool {
    font-size: 12px;
    margin-left: 24px;

    &:focus {
      outline: 0;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .tool--disabled {
    cursor: inherit !important;
    text-decoration: none !important;
    color: #bababa !important;

    .MuiSvgIcon-root {
      color: #bababa !important;
    }
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    color: #134594;
    margin-right: 6px;
    position: relative;
    top: 4px;
  }
}
