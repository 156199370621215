.Portfolio,
.Documents,
.Assistance {
  display: flex;
  flex-direction: column;
  height: 100%;

  .searchInput {
    input {
      padding: 12px 6px;
      width: 200px;
      background-color: white;
    }
  }

  .MuiPaper-root {
    height: 100%;
  }

  .MuiGrid-root {
    height: 100%;
  }

  .filesContainer {
    padding-top: 32px;
    padding-right: 16px;
    padding-left: 16px;
  }

  @media (max-width: 767px) {
    .MuiPaper-root {
      height: auto;
    }

    .MuiGrid-root {
      height: auto;
    }

    height: auto;
    padding-bottom: 70px
  }
}

// .ClientsListing {
//   height: calc(100vh - 280px);
// }
