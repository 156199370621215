body {
  margin: 0;
  font-family: "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.mt-4 {
  margin-top: 16px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-6 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-8 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.mt-2 {
  margin-top: 8px;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: 4px;
}

.gap-4 {
  gap: 8px;
}

.gap-1 {
  gap: 2px;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.w-full {
  width: 100%
}

.p-4 {
  padding: 16px;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 600;
}

.text-sm {
  font-size: .875rem
}

.text-lg {
  font-size: 1.125rem
}

.text-xl {
  font-size: 1.25rem
}

.text-2xl {
  font-size: 1.5rem
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-60 {
  opacity: 0.6;
}

.flex-col {
  flex-direction: column;
}

.DocumentsListing {
  padding-right: 16px;
  padding-left: 16px;
}

.mt-8 {
  margin-top: 32px;
}

.mt-6 {
  margin-top: 24px;
}

.flex-grow-0 {
  flex-grow: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-4 {
  padding-right: 16px;
  padding-left: 16px;
}

.text-xs {
  font-size: 0.8rem;
}

.mr-4 {
  margin-right: 16px;
}
