.link__details {
  img {
    max-width: 100%;
  }

  .MuiIconButton-root {
    color: #134594;
    padding: 10px;
  }

  .MuiFormLabel-root {
    color: #b7c2ff;
  }

  .MuiFormControlLabel-root {
    color: #134594;
  }

  .MuiInputBase-input {
    color: #134594;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: "black";
  }

  .MuiInputBase-input.Mui-disabled {
    color: "black";
  }
}
