.Contact {
  .MuiPaper-root {
    padding: 36px;
  }

  @media (max-width: 767px) {
    padding-bottom:70px;

    .MuiPaper-root {
      padding: 10px 20px;
    }
  }

  .help {
    margin-bottom: 24px;
  }

  .actions {
    text-align: right;
    padding: 10px 0;
    button {
      min-width: 150px;
    }
  }
}
