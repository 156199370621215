.PublicDocsFileLine {
  .MuiTableCell-root {
    padding: 12px;
  }

  .MuiButton-text.cancel {
    background: #ccc !important;
  }

  .DropdownCell {
    .MuiTypography-root {
      cursor: pointer;
    }
  }

  .DropdownContainer {
    position: absolute;
  }

  .filename {
    font-size: 13px;
    font-weight: bold;
    word-break: break-all;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .DocumentSize {
    white-space: nowrap;
  }

  .DropdownMenu {
    position: relative;
    right: 150px;
    z-index: 999;
  }

  .DropdownOption {
    padding: 6px 24px;
    border-bottom: 1px solid #f7f7f7;
    cursor: pointer;
    white-space: nowrap;

    .MuiSvgIcon-root {
      position: relative;
      top: 4px;
      color: #134594;
      font-size: 1.2rem;
      margin-right: 6px;
    }

    &:hover {
      background-color: #f9f7f7;
    }
  }

  .TypeCell {
    &:hover {
      cursor: pointer;
    }

    font-size: 24px;
  }

  .StarCell {
    .MuiSvgIcon-root {
      cursor: pointer;
      color: #b7c2ff;
    }
  }

  .DropdownActive {
    color: #134594;
  }

  @media (max-width: 1200px) {
    .DocumentSize {
      display: none;
    }
  }
}
