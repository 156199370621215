.Home {
  margin: -35px -12px !important;

  @media (max-width: 767px) {
    height: auto;
    padding-bottom: 70px;
  }

  .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
    position: relative;
    bottom: 0px;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 5px;
  }

  .MuiStepConnector-alternativeLabel {
    margin-top: 24px;
  }

  .MuiStepper-root {
    padding: 0px;
  }

  .step {
    z-index: 1;
    color: white;
    width: 70px;
    height: 70px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
  }

  .MuiStepLabel-label.MuiStepLabel-completed {
    color: #134594;
    font-weight: bold;
    font-size: 12px;
  }

  .step-more {
    color: #999;
    font-size: 0.85em;
  }

  .dashboard {
    flex-grow: 1;
    // min-height: 100%;
    // height: 100%;
  }

  .embedded-iframe {
    width: 100%;
    height: 100%;

    @media (max-width: 767px) {
      min-height: 450px;
    }
  }

  .assistance-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .assistance-container {
    max-width: 280px;
  }
}

.clipped-iframe {
  position: relative;
  bottom: 179px;
  height: 740px;
  overflow: hidden;
  clip-path: inset(190px 0px -28px 0px)
  
}

@media (max-width: 767px) {
  .clipped-iframe {
    bottom: 392px;
    height: 938px;
    clip-path: inset(340px 0px -28px 0px)
  }
}
