.ClientLine {
  .MuiButton-root {
    padding: 0;
    text-transform: none;

    &.Mui-disabled {
      background-color: #ebeeff;
      color: #134594;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }
  }

  .MuiButton-text.cancel {
    background: #ccc !important;
  }
}
