.Sidebar {
  .container {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .Sidebar {
    position: fixed;
    top:0;
  }
}