.HomePaper {
  padding: 10px;
  margin-top: 14px;

  .MuiTypography-h3 {
    margin-bottom: 15px;
    font-size: 18px;

    span {
      border: 4px solid #dd0f2e;
      border-top: none;
      border-right: none;
      display: inline;
      padding-left: 6px;
    }
  }
}

.HomePaper.dashboard {
  position: relative;

  .MuiTypography-h3 {
    position: absolute;
    background: white;
  }

  @media (max-width: 767px) {
    .MuiTypography-h3 {
      display: none;
    }

    min-height: 450px;
  }

}
