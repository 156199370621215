.ApplicationItem {
  cursor: pointer;

  &:hover {
    box-shadow: 4px 4px 4px #bbb;
  }

  .MuiSvgIcon-root {
    color: #b7c2ff;
  }

  h4 {
    color: #134594;
    font-size: #134594;
    font-weight: bold;
  }

  .infoContainer {
    display: flex;
    padding: 6px 12px;
  }

  .titleContainer {
    flex: 1 1 auto;
  }

  .imageContainer {
    overflow: hidden;
    // width: 300px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    height: auto;
    max-height: 60%;
    max-width: 60%;
    display: inline-block;
    margin: 0 auto;
    padding: 10px;
    // width: 400px;
    //height: 200px;
  }
}
