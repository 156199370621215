.FolderSelector {
  text-transform: capitalize;
  height: 26px;
  padding-left: 45px;
  padding-right:30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content : space-between;

  &:hover {
    cursor: pointer;
    background-color: #b7c2ff6e;
  }

  &.active {
    background-color: #b7c2ff;
  }
}
