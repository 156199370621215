.LinkCategory {
  .titleContainer {
    background-color: rgba(183, 194, 255, 0.19);
    color: #586ee5;
    padding: 12px 16px;

    h4 {
      font-size: 12px;
    }
  }

  .LinkCategoryLink {
    padding: 6px 24px;
  }

  p {
    color: #737373;
    margin: 0;
    font-style: italic;
  }
}
