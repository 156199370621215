.Settings {
  color: #134594;
  height: 100vh;

  @media (max-width: 767px) {
    height: auto;
    padding-bottom: 70px;
  }

  strong {
    font-size: 24px;
    font-weight: bold;
  }

  .changePasswordContainer {
    margin-bottom: 24px;
    text-align: right;

    @media (max-width: 767px) {
      text-align: left;
      margin: 10px 0;
    }
  }

  .MuiTabs-flexContainer {
    justify-content: center;
    border-bottom: 2px solid #efefef;
  }

  .MuiTabs-indicator {
    background-color: #e6223f;
  }

  .applicationsTable {
    overflow-y: scroll;
    height: 90%;

    @media (max-width: 767px) {
      tr {
        border-bottom: solid 2px #eee
      }

      th,
      td {
        border: none
      }
    }
  }

  .MuiFormLabel-root {
    color: #b7c2ff;
  }

  .MuiInputBase-input {
    color: #134594;
  }

  .MuiCheckbox-root {
    color: rgba(0, 0, 0, 0.34);
    padding: 5px;
  }

  .MuiCheckbox-colorPrimary.Mui-disabled {
    color: #b7c2ff;
  }

  th {
    color: #134594;
    font-size: 20px;
  }

  td {
    color: #707070;
    font-size: 15px;
  }

  .app-status {
    padding: 5px;
    background: #efefef;
    border-radius: 25px;
    text-align: center;
    font-size: 12px;

    &.enable {
      background: #134594;
      color: white;
    }

    &.disable {}
  }

  .settings__users {
    width: 80vw;
    height: 100%;
    display: flex;
    flex-direction: row;

    .appChip {
      // border-bottom: 1px solid #134594;
      background-color: white;
      color: #134594;
      padding: 0 2px;
      border-radius: 0px;
      font-size: 0.8rem;
    }

    .missionChip {
      background-color: "white";
      padding: 0 8px;
      border-radius: 3px;
    }

    &__new {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 0px;
      overflow: auto;

      h1 {
        font-weight: 200;
        font-size: 26px;
        color: #134594;
      }

      &-create {
        background: #134594 !important;
        color: white !important;
        border-radius: 29px !important;
        border: 56px;
        padding: 8px 15px;
        box-shadow: 3px 6px 9px #00000029;
        text-transform: inherit;
        float: left;
        width: 120px;
        margin: 15px 0px;

        &.Mui-disabled {
          background-color: #707070 !important;
        }
      }

    }

    &__list {
      width: 550px;
      max-height: 95%;
      border-right: 1px dashed #efefef;
      //border-top: dotted 1px $dark;
      position: relative;
      padding: 5px;
      overflow: hidden;

      &-head {
        h2 {
          color: #134594;
          font-weight: normal;
          font-size: 20px;
        }

        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 60px;
        background: white;
        border-bottom: 1px solid #eee;
        top: 0;
        padding-right: 0 25px;
        z-index: 1;
        width: calc(100% - 10px);

        &-action {
          margin-right: 10px;
          font-weight: bold;
          color: #707070;
        }
      }

      &-details {
        height: 100%;
        overflow: auto;
      }

      &-user {
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        // border-bottom: solid 1px $dark;
        // border-left: solid 5px $dark;
        margin-bottom: 10px;
        font-size: 12px;
        min-height: 70px;
        cursor: pointer;

        &:hover {
          border-left: solid 5px #dd0f2e;
        }

        &__name {
          font-size: 14px;
          font-weight: bold;
          color: #134594;
        }

        &__avatar {
          background: #b7c2ff !important;
        }

        &__delete {
          color: #586ee5;
        }
      }
    }

    &__details {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;

      h1 {
        font-weight: 200;
        font-size: 26px;
        color: #134594;
      }

      &-form {
        max-width: 800px;
        display: flex;
        justify-content: flex-start;
      }

      &-form-inputs {
        color: #134594;
      }

      &-form-actions {
        display: flex;
        width: 300px;
        flex-direction: column;
        padding: 15px 30px 15px 0;
      }
    }
  }

  .MuiButton-text.cancel {
    background: #ccc !important;
  }
}
