.MuiTableCell-head {
  line-height: 1;
}

.MuiTableCell-body {
  line-height: 1.1;
}

.MuiTableCell-root {
  padding: 11px;
}

.indicatorBody {
  font-size: 0.8rem;
}

.removeFilter {
  position: absolute;
  right: 15px;
  top: 30%;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:disabled {
    color: #134594;
  }
}


.MuiFormControl-root {
  min-width: 180px;
  margin-bottom: 10px;
}

.tableTitle {
  font-size: 1rem;
  color: #333;
}

.tableTitle.var {
  color: #bbb;
  font-weight: bold;
}
