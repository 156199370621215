.DeleteModal {
  .documentName {
    color: #414141;
    font-size: 13px;
  }

  .confirmationText {
    margin-top: 48px;
  }

  .confirmationBody {
    margin: 0 12px;
  }

  .DocumentIcon {
    margin-right: 6px;
    font-size: 24px;
    position: relative;
    top: 4px;
  }

  .MuiButton-text.cancel {
    background: #ccc !important;
  }
}
