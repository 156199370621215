.TicketDetails {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    height: auto;
    padding-bottom: 70px;
  }
}

.TicketInfos {
  .MuiInputBase-root,
  .MuiSelect-select.MuiSelect-select {
    font-size: 0.8rem;
  }
  .MuiButton-text.cancel {
    background: #ccc !important;
  }
}

.backButton {
  justify-content: left !important;
}
