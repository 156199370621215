.DocumentsListing {
  .markAllAsRead {
    font-size: 0.9rem;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &:disabled {
      color: #aaa;
    }
  }

  .MuiBadge-dot {
    width: 5px;
    padding: 0px;
    left: 7px;
    position: relative;
  }
}
