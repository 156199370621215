.Links {

  @media (max-width: 767px) {
    height: auto;
    padding-bottom: 70px;
  }
  
  .MuiPaper-root {
    height: 100%;
  }

}
