.DocumentsToolbar {
  .tool {
    margin-left: 24px;

    &:focus {
      outline: 0;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .tool--disabled {
    margin-left: 24px;

    cursor: inherit !important;
    text-decoration: none !important;
    color: #bababa !important;

    .MuiSvgIcon-root {
      color: #bababa !important;
    }
  }

  .MuiSvgIcon-root {
    font-size: 20px;
    color: #134594;
    vertical-align: middle;
  }

  .MuiButton-text.cancel {
    background: #ccc !important;
  }
}
