.Ged {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 767px) {
    padding-bottom: 70px;
  }

  .searchInput {
    input {
      padding: 12px 6px;
      width: 200px;
      background-color: white;
    }
  }

  .GedCategoryContainer {
    display: flex;
    flex-direction: column;
    // font-size: 0.9rem;
  }

  .MuiPaper-root {
    height: 100%;
  }

  .MuiGrid-root {
    height: 100%;
  }

  .filesContainer {
    height: 100%;
    display: flex;
    flex-direction: column;

    .MuiTableCell-stickyHeader {
      background: white;
    }

    .MuiTableCell-root {
      font-size: 14px;

      .DocumentIcon {
        font-size: 1.2rem;
      }
    }
  }

  .processFilter {
    font-weight: "bolder";
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
