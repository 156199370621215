.DownloadDocument {
  flex-grow: 1;
  max-width: 95%;

  .documentName {
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }

  .documentSize {
    color: #aaa;
    margin-bottom: 15px;
  }

  h2 {
    color: #134594;
    font-size: 36px;
    font-weight: inherit;
    margin-bottom: 36px;
  }

  h3 {
    color: #134594;
    font-size: 24px;
    font-weight: inherit;
    margin-bottom: 24px;
  }

  input {
    background-color: white;
  }

  .MuiFormControl-root {
    margin: 0 0 12px 0;
  }

  .MuiCircularProgress-root {
    color: white;
  }

  button {
    margin-top: 36px !important;

    &:hover {
      background-color: darken(#134594, 1%);
    }
  }

  a {
    color: #42b7c8;
    font-size: 15px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  form {
    text-align: left;
  }

  label {
    color: #b7c2ff;
  }
}
