.Admin {
  padding: 0px;
  font-size: 0.8rem;

  .MuiTabs-root {
    background-color: #134594;
  }

  .MuiBox-root {
    padding: 10px;
  }

  .loader-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }

  .loader-spinner {
    border: 5px solid #f3f3f3;
    animation: spin 0.75s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .loader-spinner.center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1001;
  }

  .loader-spinner.right {
    position: absolute;
    top: 4px;
    right: 20px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
