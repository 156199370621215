.PageTitle {
  margin-bottom: 36px;
  display: flex;

  @media (max-width: 767px) {
    height: auto;
    flex-direction: row;

    .MuiGrid-root {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .titleInline {
    border-bottom: 4px solid #e6223f;
    white-space: nowrap;
  }
}
