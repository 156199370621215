.GedMoveModal {
  .GedMoveModalContent {
    min-width: 500px;

    @media (max-width: 767px) {
      max-width: 95%;
      min-width: 95%;
    }

    .moveModalContainer {
      height: 300px;
      overflow: auto;
    }
  }

  .folderName {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .emptyFolder {
    text-align: center;
    color: #bbb;
    padding: 48px 0;
    font-size: 12px;
  }

  .rootFolderSelection {
    margin-top: 6px;
    padding: 12px 24px;
    cursor: pointer;

    &:hover {
      background-color: #b7c2ff6e;
    }
  }
  .MuiButton-text.cancel {
    background: #ccc !important;
  }
}
