.document__preview {
  img {
    max-width: 100%;
  }

  .MuiGrid-item {
    border: 0px solid #cccccc;
    padding: 10px !important;
  }

  .MuiGrid-item.hidden {
    display: none;
  }

  .passwordProtection {
    font-size: 0.2rem !important;
    margin-top: 10px;
  }

  .MuiFormLabel-root {
    color: #b7c2ff;
  }

  .MuiInputBase-input {
    color: #134594;
  }

  .MuiFormLabel-root.Mui-disabled {
    color: #134594;
  }

  .MuiInputBase-input.Mui-disabled {
    color: #999;
  }
}
