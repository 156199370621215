.DashboardItem {
  cursor: pointer;

  &:hover {
    box-shadow: 4px 4px 4px #bbb;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .MuiSvgIcon-root {
    color: #b7c2ff;
  }

  h4 {
    text-transform: uppercase;
    color: #6ab1ff;
    font-weight: bold;
    font-size: 0.85em;
  }

  small {
    color: rgba(0, 0, 0, 0.3);
  }
}
