.Layout {
  .Toast {
    background-color: transparent;
  }

  .Toast--error {
    background-color: red;

    .MuiSnackbarContent-root {
      background-color: red;
    }
  }

  .Toast--info {
    background-color: blue;

    .MuiSnackbarContent-root {
      background-color: blue;
    }
  }

  .Toast--success {
    background-color: green;

    .MuiSnackbarContent-root {
      background-color: green;
    }
  }

  .Toast--warn {
    background-color: orange;

    .MuiSnackbarContent-root {
      background-color: orange;
    }
  }

  .MuiSnackbarContent-action {
    padding-left: 8px;
    padding-right: 15px;
  }

  .MuiButton-colorInherit {
    font-size: xx-small;
    padding: 2px 3px;
    border-radius: 10px !important;
  }
}
