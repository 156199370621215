.SidebarClientInfo {
  font-size: 12px;
  padding: 16px 0px;

  .infoContainer {
    display: flex;
    margin-bottom: 6px;
    line-height: 140%;

    div {
      display: block;
      font-size: 0.95em;
    }

    .label {
      flex: 0 0 90px;
    }
  }

  .identity {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

    .MuiAvatar-root {
      background-color: #b7c2ff;
      flex: none;
    }

    .initials:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    span {
      flex: 1 1 auto;
      padding: 12px;
    }
    .MuiSelect-selectMenu {
      font-size: 0.8em;
    }
  }
}
