.GedCategory {
  flex: 1 1 auto;

  .titleContainer {
    background-color: #f4f4f8;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-left: solid 4px #e6223f;
    padding-left: 40px;
    height: 50px;

    &.active {
      background-color: #97a2cf;

    }
  }

  &:hover {
    cursor: pointer;
  }


  .content {
    padding: 8px 0;
  }

}
