.GedFilesListing {
  overflow-y: visible;
  overflow-x: hidden;
  flex-grow: 1;
  position: relative;

  .MuiTableBody-root {
    overflow: auto;
    max-height: 400px;
  }

  .emptyFolder {
    text-align: center;
    color: #bbb;
    padding: 48px 0;
    font-size: 12px;
  }
  .MuiBadge-dot {
    width: 5px;
    padding: 0px;
    left: 7px;
    position: relative;
  }

  .sortableHeader {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .sortIcon {
    vertical-align:middle;
    height: 1rem;
  }
}
